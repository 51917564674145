import { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { createStyles, makeStyles, TableCell, TableRow, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { themeOrange as theme } from 'theme';
import { User } from '../../users';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      position: 'relative',
      '&&:hover $item': {
        backgroundColor: 'transparent',
      },
    },
    current: {
      '&:after': {
        content: "''",
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: 'calc(100% + 1px)',
        display: 'block',
        borderRadius: 2,
      },
    },
    name: {
      fontSize: 12,
      marginRight: theme.spacing(2),
      '&&': {
        padding: 0,
      },
    },
    fill: {
      width: '100%',
      height: '100%',
      padding: theme.spacing(0.75),
      backgroundColor: theme.palette.background.default,
      borderBottom: `4px solid ${theme.palette.background.paper}`,
    },
    item: {
      width: 70,
      borderLeft: '1px solid transparent',
      borderRight: '1px solid transparent',
      fontSize: 12,
      textAlign: 'center',
      padding: theme.spacing(0.75),
      marginRight: theme.spacing(2),
      backgroundColor: theme.palette.background.default,
      borderBottom: `4px solid ${theme.palette.background.paper}`,
    },
    pink: {
      color: theme.palette.error.main,
    },
  }),
);

type SupportItemProps = {
  id: string;
  name: string;
  isActive: boolean;
  contracts: number[];
  payment: number[];
  telematics: number[];
  shipment: number[];
  verification: number[];
  shipmentReport: number[];
  max: number;
  isCurrent?: boolean;
};

export const SupportItem = (props: SupportItemProps) => {
  const classes = useStyles(props);
  const {
    isCurrent = false,
    max,
    contracts,
    payment,
    telematics,
    shipment,
    verification,
    shipmentReport,
    name,
    isActive,
  } = props;
  const currentClass = isCurrent ? classes.current : null;

  const color = isCurrent ? theme.palette.secondary.main : theme.palette.secondary.light;

  const [proc, setProc] = useState<number>(0);
  const anim = useSpring({
    background: `linear-gradient(to right, ${color} ${proc}%, ${theme.palette.background.default} 0)`,
    config: { duration: 500 },
  });

  useEffect(() => {
    const amount = contracts[0] + payment[0] + telematics[0] + shipment[0];
    const value = max > 0 ? (100 * amount) / max : 0;
    setProc(value);
  }, [contracts, payment, telematics, shipment, max]);

  return (
    <TableRow className={clsx(classes.root, currentClass)}>
      <TableCell className={classes.name}>
        <animated.div style={anim} className={classes.fill}>
          <User name={name} isActive={isActive} />
        </animated.div>
      </TableCell>
      <TableCell align="center" className={classes.item}>
        {contracts[0]} {contracts[1] ? <span className={classes.pink}>({contracts[1]})</span> : ''}
      </TableCell>
      <TableCell align="center" className={classes.item}>
        {verification[0]}{' '}
        {verification[1] ? <span className={classes.pink}>({verification[1]})</span> : ''}
      </TableCell>
      <TableCell align="center" className={classes.item}>
        {payment[0]} {payment[1] ? <span className={classes.pink}>({payment[1]})</span> : ''}
      </TableCell>
      <TableCell align="center" className={classes.item}>
        {telematics[0]}{' '}
        {telematics[1] ? <span className={classes.pink}>({telematics[1]})</span> : ''}
      </TableCell>
      <TableCell align="center" className={classes.item}>
        {shipment[0]} {shipment[1] ? <span className={classes.pink}>({shipment[1]})</span> : ''}
      </TableCell>
      <TableCell align="center" className={classes.item}>
        {shipmentReport[0]}{' '}
        {shipmentReport[1] ? <span className={classes.pink}>({shipmentReport[1]})</span> : ''}
      </TableCell>
    </TableRow>
  );
};
