import { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { Currency, NewPipelineReportGroupItem } from 'schema/serverTypes';
import { formatMoney, formatNumber } from 'components/utils';
import { themeOrange as theme } from 'theme';
import { PipelineUserTable } from './PipelineUserTable';
import { IconSprite } from '../../../icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor: 'pointer',
    },
    awaiting: {
      display: 'flex',
      color: theme.palette.text.primary,
      fontSize: 12,
      marginLeft: 100,
      marginBottom: theme.spacing(0.5),
      '&$total': {
        fontWeight: 700,
        color: theme.palette.text.primary,
        marginBottom: theme.spacing(1.25),
      },
    },
    fill: {
      fontSize: 12,
      width: '100%',
      minHeight: 8,
      borderLeft: '1px solid' + theme.palette.common.white,
      '&$total span': {
        display: 'inline-block',
        height: '100%',
        padding: theme.spacing(0.5, 1),
      },
    },
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    head: {
      position: 'relative',
      flex: '0 0 100px',
      '&$total': {
        fontWeight: 700,
      },
    },
    bar: {
      width: '100%',
      display: 'flex',
      flex: '1 1 auto',
      color: theme.palette.common.white,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    caption: {
      marginLeft: 100,
      display: 'flex',
      color: theme.palette.text.primary,
      fontSize: 12,
      marginTop: 1,
      marginBottom: theme.spacing(1.5),
      '&$total': {
        fontWeight: 700,
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(2),
      },
    },
    usersOpen: {
      position: 'absolute',
      right: theme.spacing(1.5),
      fill: theme.palette.primaryGradient[600],
      top: '50%',
      transformOrigin: 'top',
      transform: 'rotate(180deg) translateY(-50%)',
    },
    usersClosed: {
      position: 'absolute',
      right: theme.spacing(1.5),
      fill: theme.palette.primaryGradient[200],
      top: '50%',
      transformOrigin: 'top',
      transform: 'translateY(-50%)',
    },
    total: {},
  })
);

type PipelineItemProps = NewPipelineReportGroupItem & { userList?: boolean };

export const PipelineItem = (props: PipelineItemProps) => {
  const classes = useStyles(props);
  const {
    name,
    totalAwaitingCount,
    totalAwaitingAmount,
    totalReadyCount,
    totalReadyAmount,
    totalCommitedCount,
    totalCommitedAmount,
    totalNoCommitedCount,
    totalNoCommitedAmount,
    totalPostponedCount,
    totalPostponedAmount,
    totalLostSaleCount,
    totalLostSaleAmount,
    margin,
    users,
    userList = true,
  } = props;

  const [proc, setProc] = useState<number[]>([0, 0, 0, 0, 0]);
  const [
    totalReadyAmountProc,
    totalCommitedAmountProc,
    totalNoCommitedAmountProc,
    totalPostponedAmountProc,
    totalLostSaleAmountProc,
  ] = proc;
  const anim1 = useSpring({
    background: theme.palette.success.main,
    minWidth: totalReadyAmountProc > 0 ? 130 : 0,
    width: `${totalReadyAmountProc}%`,
    config: { duration: 500 },
  });
  const anim2 = useSpring({
    background: theme.palette.warning.light,
    minWidth: totalCommitedAmountProc > 0 ? 130 : 0,
    width: `${totalCommitedAmountProc}%`,
    config: { duration: 500 },
  });
  const anim3 = useSpring({
    background: theme.palette.secondary.main,
    minWidth: totalNoCommitedAmountProc > 0 ? 130 : 0,
    width: `${totalNoCommitedAmountProc}%`,
    config: { duration: 500 },
  });
  const anim4 = useSpring({
    background: theme.palette.text.secondary,
    minWidth: totalPostponedAmountProc > 0 ? 130 : 0,
    width: `${totalPostponedAmountProc}%`,
    config: { duration: 500 },
  });
  const anim5 = useSpring({
    background: theme.palette.primary.main,
    minWidth: totalLostSaleAmountProc > 0 ? 130 : 0,
    width: `${totalLostSaleAmountProc}%`,
    config: { duration: 500 },
  });

  useEffect(() => {
    const max = totalReadyAmount + totalCommitedAmount + totalNoCommitedAmount;
    const totalReadyAmountProc = max > 0 ? (100 * totalReadyAmount) / max : 0;
    const totalCommitedAmountProc = max > 0 ? (100 * totalCommitedAmount) / max : 0;
    const totalNoCommitedAmountProc = max > 0 ? (100 * totalNoCommitedAmount) / max : 0;
    const totalPostponedAmountProc = max > 0 ? (100 * totalPostponedAmount) / max : 0;
    const totalLostAmountProc = max > 0 ? (100 * totalLostSaleAmount) / max : 0;
    setProc([
      totalReadyAmountProc,
      totalCommitedAmountProc,
      totalNoCommitedAmountProc,
      totalPostponedAmountProc,
      totalLostAmountProc,
    ]);
  }, [
    totalReadyAmount,
    totalCommitedAmount,
    totalNoCommitedAmount,
    totalPostponedAmount,
    totalLostSaleAmount,
  ]);

  const [usersOpen, setUsersOpen] = useState<boolean>(false);

  const headClass =
    name === 'Всего' || usersOpen ? clsx(classes.head, classes.total) : classes.head;
  const barClass = name === 'Всего' ? clsx(classes.fill, classes.total) : classes.fill;
  const captionClass = name === 'Всего' ? clsx(classes.caption, classes.total) : classes.caption;
  const awaitingClass = name === 'Всего' ? clsx(classes.awaiting, classes.total) : classes.awaiting;

  const toggleUserOpen = () => {
    if (userList) {
      setUsersOpen((prev) => !prev);
    }
  };

  const openIconClassName = usersOpen ? classes.usersOpen : classes.usersClosed;

  return (
    <>
      <div onClick={toggleUserOpen} className={classes.root}>
        <div className={awaitingClass}>
          Ожидается за период{' '}
          {`${totalAwaitingCount} | ${formatMoney({
            amount: totalAwaitingAmount,
            currency: Currency.Ruble,
          })} | ${formatNumber(margin, 1)}%`}
        </div>
        <div className={classes.container}>
          <div className={headClass}>
            {name}
            {userList && (
              <IconSprite icon={'openArrow'} width={8} height={7} className={openIconClassName} />
            )}
          </div>
          <div className={classes.bar}>
            <animated.div style={anim1} className={barClass}>
              <span>{name === 'Всего' && 'Отгрузили'}</span>
            </animated.div>
            <animated.div style={anim2} className={barClass}>
              <span>{name === 'Всего' && 'Committed'}</span>
            </animated.div>
            <animated.div style={anim3} className={barClass}>
              <span>{name === 'Всего' && 'No Committed'}</span>
            </animated.div>
            <animated.div style={anim4} className={barClass}>
              <span>{name === 'Всего' && 'Postponed'}</span>
            </animated.div>
            <animated.div style={anim5} className={barClass}>
              <span>{name === 'Всего' && 'Lost'}</span>
            </animated.div>
          </div>
        </div>
        <div className={captionClass}>
          <div
            style={{
              width: `${totalReadyAmountProc}%`,
              minWidth: totalReadyAmountProc > 0 ? 130 : 0,
            }}
          >
            {totalReadyAmount > 0
              ? `${totalReadyCount} | ${formatMoney({
                  amount: totalReadyAmount,
                  currency: Currency.Ruble,
                })}`
              : null}
          </div>
          <div
            style={{
              width: `${totalCommitedAmountProc}%`,
              minWidth: totalCommitedAmountProc > 0 ? 130 : 0,
            }}
          >
            {totalCommitedAmount > 0
              ? `${totalCommitedCount} | ${formatMoney({
                  amount: totalCommitedAmount,
                  currency: Currency.Ruble,
                })}`
              : null}
          </div>
          <div
            style={{
              width: `${totalNoCommitedAmountProc}%`,
              minWidth: totalNoCommitedAmountProc > 0 ? 130 : 0,
            }}
          >
            {totalNoCommitedAmount > 0
              ? `${totalNoCommitedCount} | ${formatMoney({
                  amount: totalNoCommitedAmount,
                  currency: Currency.Ruble,
                })}`
              : null}
          </div>
          <div
            style={{
              width: `${totalPostponedAmountProc}%`,
              minWidth: totalPostponedAmountProc > 0 ? 130 : 0,
            }}
          >
            {totalPostponedAmount > 0
              ? `${totalPostponedCount} | ${formatMoney({
                  amount: totalPostponedAmount,
                  currency: Currency.Ruble,
                })}`
              : null}
          </div>
          <div
            style={{
              width: `${totalLostSaleAmountProc}%`,
              minWidth: totalLostSaleAmountProc > 0 ? 130 : 0,
            }}
          >
            {totalLostSaleAmount > 0
              ? `${totalLostSaleCount} | ${formatMoney({
                  amount: totalLostSaleAmount,
                  currency: Currency.Ruble,
                })}`
              : null}
          </div>
        </div>
      </div>
      {usersOpen && <PipelineUserTable users={users} />}
    </>
  );
};
