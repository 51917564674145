import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from '@material-ui/lab/useAutocomplete';
import { useCallback, useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CounterpartyFormValues } from '../types';
import { CounterpartyFormProps, defaultValues } from './types';
import { useCounterpartySearchQuery } from './useCounterpartySearchQuery';
import { CounterpartyViewModel } from 'schema/serverTypes';
import { useCounterpartiesBackendQuery } from 'services';
import { AutocompleteInputChangeReason } from '@material-ui/lab/useAutocomplete/useAutocomplete';

const getInnOptionLabel = (option: CounterpartyAutocompleteOption | string) => {
  if (typeof option === 'string') {
    return option;
  }
  return option.inn ?? option;
};

const renderOption = (option: CounterpartyAutocompleteOption, state: object) => {
  return option.label;
};

const getOptionSelected = (
  option: CounterpartyAutocompleteOption,
  value: CounterpartyAutocompleteOption
) => {
  return option.inn === value.inn;
};

export type CounterpartyAutocompleteOption = Pick<
  CounterpartyViewModel,
  'inn' | 'name' | 'isAlreadyExist' | 'isPhysic'
> & {
  label: string;
};

const createOption = ({
  inn,
  name,
  isAlreadyExist,
  isPhysic,
}: CounterpartyViewModel): CounterpartyAutocompleteOption => {
  return {
    inn,
    name,
    label: isPhysic ? `${inn} (физлицо)` : `${inn} (${name})`,
    isAlreadyExist,
    isPhysic,
  };
};

type UseAutocompleteFieldProps = CounterpartyFormProps & {
  name: 'inn' | 'name';
};

const useAutocompleteField = (props: UseAutocompleteFieldProps) => {
  const { control, name } = props;
  const { t } = useTranslation();

  const {
    field,
    fieldState: { invalid, error },
  } = useController<CounterpartyFormValues, typeof name>({
    control,
    name,
    rules: {
      maxLength: {
        value: 12,
        message: t('Maximum number of characters is', { count: 12 }),
      },
      minLength: {
        value: 12,
        message: t('Minimum number of characters is', { count: 12 }),
      },
      required: {
        value: true,
        message: t('Required'),
      },
    },
  });

  const [input, setInput] = useState('');
  const { data: innData = [] } = useCounterpartySearchQuery(input);
  const options = innData.map(createOption);

  return {
    field,
    error: invalid,
    helperText: error?.message,
    input,
    setInput,
    options,
  };
};

//any = CounterpartyFormProps
export const useCounterpartyIndividualAutocomplete = (props: any) => {
  const {
    field: { value: inn },
    setInput: setInnInput,
    ...innField
  } = useAutocompleteField({ ...props, name: 'inn' });

  let innOptions = innField.options;

  const { reset, setValue } = props;

  useEffect(() => {
    const selected = innOptions.find((t) => t.inn === inn);
    if (selected) {
      window.location.href = selected.isPhysic
        ? `/counterparties/individual/${selected.inn}`
        : `/counterparties/${selected.inn}`;
    }
  }, [inn, innOptions]);

  const onInnInputChange = useCallback(
    (_ev: React.ChangeEvent<{}>, v: string, reason: AutocompleteInputChangeReason) => {
      console.log(1, 'onInnInputChange', reason);
      setValue('inn', v);
      setInnInput(v);
    },
    [setInnInput, setValue]
  );

  const onInnChange = useCallback(
    (
      _e: React.ChangeEvent<{}>,
      value: CounterpartyAutocompleteOption | null,
      reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<CounterpartyAutocompleteOption | null>
    ) => {
      const values = reason === 'clear' || value === null ? defaultValues : value;
      if (values.isAlreadyExist) {
        window.location.href = values.isPhysic
          ? `/counterparties/individual/${values.inn}`
          : `/counterparties/${values.inn}`;
      } else {
        reset(values);
        setInnInput(values.inn);
      }
    },
    [reset, setInnInput]
  );

  const requestUrl = `external/${inn}`;
  useCounterpartiesBackendQuery<CounterpartyViewModel>(requestUrl, {
    enabled: inn !== '',
    refetchInterval: false,
    refetchOnWindowFocus: false,
    onSuccess: (result: any) => {
      if (result.status !== 404) {
        window.location.href = `/counterparties/${inn}`;
      }
    },
  });

  return {
    inn: {
      ...innField,
      options: innOptions,
      onInputChange: onInnInputChange,
      onChange: onInnChange,
      value: innOptions.find((t) => t.inn === inn) ?? null,
      getOptionLabel: getInnOptionLabel,
      getOptionSelected,
      renderOption,
    },
  };
};
