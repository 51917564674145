import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, TableCell, TableRow, Theme } from '@material-ui/core';
import { useNotAssignedIssuesCountReportRequest } from 'services/api/useReportsBackend';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      backgroundColor: 'transparent',
      '&&:hover $total': {
        backgroundColor: 'transparent',
      },
    },
    total: {
      display: 'flex',
      marginRight: theme.spacing(3),
      background: 'transparent',
      '&&': {
        padding: 0,
      },
    },
    name: {
      padding: theme.spacing(0.75, 0, 0.75, 0.75),
      marginRight: theme.spacing(3),
      fontSize: 12,
      fontWeight: 700,
    },
    item: {
      borderLeft: '1px solid transparent',
      borderRight: '1px solid transparent',
      fontSize: 12,
      textAlign: 'center',
      padding: theme.spacing(0.75, 0, 0.75, 0.75),
      background: 'white',
      marginRight: theme.spacing(3),
    },
    pink: {
      color: theme.palette.error.main,
    },
  }),
);

type SupportTotalProps = {};

export const SupportTotal = (props: SupportTotalProps) => {
  const classes = useStyles(props);
  const { t } = useTranslation();

  const { data } = useNotAssignedIssuesCountReportRequest();

  const contracts = data?.contracts ?? [0, 0];
  const payment = data?.payments ?? [0, 0];
  const telematics = data?.telematics ?? [0, 0];
  const shipment = data?.shipments ?? [0, 0];
  const verification = data?.verifications ?? [0, 0];
  const shipmentReport = data?.shipmentReports ?? [0, 0];

  return (
    <TableRow className={classes.root}>
      <TableCell className={classes.total} colSpan={6}>
        <div className={classes.name}>{t('Tasks not sorted')}</div>
        <div className={classes.item}>
          {t('Contracts')} {contracts[0]}{' '}
          {contracts[1] ? <span className={classes.pink}>({contracts[1]})</span> : ''}
        </div>
        <div className={classes.item}>
          {t('IssueType.Verification')} {verification[0]}{' '}
          {verification[1] ? <span className={classes.pink}>({verification[1]})</span> : ''}
        </div>
        <div className={classes.item}>
          {t('Payment')} {payment[0]}{' '}
          {payment[1] ? <span className={classes.pink}>({payment[1]})</span> : ''}
        </div>
        <div className={classes.item}>
          {t('Telematics')} {telematics[0]}{' '}
          {telematics[1] ? <span className={classes.pink}>({telematics[1]})</span> : ''}
        </div>
        <div className={classes.item}>
          {t('Act request')} {shipment[0]}{' '}
          {shipment[1] ? <span className={classes.pink}>({shipment[1]})</span> : ''}
        </div>
        <div className={classes.item}>
          {t('IssueType.ShipmentReport')} {shipmentReport[0]}{' '}
          {shipmentReport[1] ? <span className={classes.pink}>({shipmentReport[1]})</span> : ''}
        </div>
      </TableCell>
    </TableRow>
  );
};
