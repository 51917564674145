import { FixedAmountInput } from '../AmountInput';
import { AmountType } from 'schema';
import { useTranslation } from 'react-i18next';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { CalculatorFormValues } from '../types';
import { useEffect } from 'react';

type PrepaymentDealerPercentProps = {
  control: Control<CalculatorFormValues, any>;
  watch: UseFormWatch<CalculatorFormValues>;
  setValue: UseFormSetValue<CalculatorFormValues>;
};

export const PrepaymentDealerPercent = (props: PrepaymentDealerPercentProps) => {
  const { t } = useTranslation();

  const { watch, setValue } = props;

  const advanceDkpAmount = watch('advanceDkpAmount');

  useEffect(() => {
    if (advanceDkpAmount?.type === AmountType.Percents) {
      setValue('prepaymentDealerPercent', 100 - advanceDkpAmount?.value);
    }
  }, [advanceDkpAmount?.value, advanceDkpAmount?.type, setValue]);

  return (
    <FixedAmountInput
      name="prepaymentDealerPercent"
      label={`${t('AmountPrepaymentSupplier')}, %`}
      required
      amountMode={AmountType.Percents}
      max={
        advanceDkpAmount?.type === AmountType.Percents
          ? 100 - (advanceDkpAmount?.value ?? 0)
          : undefined
      }
    />
  );
};
