import React, { useState, useEffect } from 'react';
import sprite from '../../img/svg-sprite.svg';

type IconData = {
  id: string;
  viewBox: string | null;
  width: string;
  height: string;
};

export const SvgIconsPage = () => {
  const [icons, setIcons] = useState<IconData[]>([]);
  useEffect(() => {
    const fetchSVG = async () => {
      const response = await fetch(sprite);
      const svgText = await response.text();
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(svgText, 'image/svg+xml');
      const symbols = svgDoc.querySelectorAll('symbol');

      const iconData = Array.from(symbols).map<IconData>((symbol) => {
        const viewBox = symbol.getAttribute('viewBox');
        let width = 'unknown';
        let height = 'unknown';

        if (viewBox) {
          const viewBoxValues = viewBox.split(' ');
          if (viewBoxValues.length === 4) {
            width = viewBoxValues[2];
            height = viewBoxValues[3];
          }
        }

        return {
          id: symbol.id,
          viewBox,
          width,
          height,
        };
      });

      setIcons(iconData);
    };

    fetchSVG();
  }, []);

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'flex-start' }}>
      {icons.map((icon) => (
        <div key={icon.id} style={{ flex: '0 0 100px', textAlign: 'center' }}>
          <svg style={{ width: '50px', height: '50px' }}>
            <use href={`${sprite}#${icon.id}`} />
          </svg>
          <p>{icon.id}</p>
          <p>
            {icon.width} x {icon.height}
          </p>
        </div>
      ))}
    </div>
  );
};
