import { useCallback, useEffect, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { usePaging } from 'components/paging';
import { WikiFilterFormProps } from './types';
import { IssueFile, SortOrder, WikiSortBy } from '../../schema';
import { useWikiListQuery } from '../../services/api/useWikiBackend';

export type WikiListFilter = {
  tags?: string[];
  search?: string;
};

export type WikiArticleViewModel = {
  id: number;
  title: string;
  description: string;
  name: string;
  date: string;
  file?: IssueFile;
  link: '';
};

export const useWikiListData = (defaultValues?: WikiListFilter) => {
  const { control, reset, setValue } = useForm<WikiListFilter>({
    mode: 'all',
    defaultValues,
  });

  const onReset = useCallback(() => {
    reset({});
  }, [reset]);

  const { page, pageSize, onPageChanged, onPageSizeChanged } = usePaging();
  const tags = useWatch({ control, name: 'tags' });
  const search = useWatch({ control, name: 'search' });

  useEffect(() => {
    onPageChanged(1);
  }, [onPageChanged, tags, search]);

  const { data, isLoading } = useWikiListQuery({
    sortBy: WikiSortBy.UpdatedDate,
    order: SortOrder.desc,
    tags,
    search,
    page,
    pageSize,
  });

  return useMemo(() => {
    const rows = data?.data ?? [];

    return {
      rows,
      isLoading,
      paging: {
        pageCount: data?.pageCount ?? 0,
        totalCount: data?.totalCount ?? 0,
        page,
        pageSize,
        dataCount: rows.length,
        onPageChanged,
        onPageSizeChanged,
      },
      filter: {
        control,
        onReset,
        setValue,
      } as WikiFilterFormProps,
    };
  }, [
    page,
    pageSize,
    control,
    onReset,
    setValue,
    onPageChanged,
    onPageSizeChanged,
    data?.pageCount,
    data?.totalCount,
    data?.data,
    isLoading,
  ]);
};
