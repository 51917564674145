import { useCallback } from 'react';
import { useWatch, Control, UseFormSetValue } from 'react-hook-form';
import { Chip } from 'components';
import { FutureShipmentFilterFormValues } from './types';
import { useTranslation } from 'react-i18next';

export type FutureShipmentChipsProps = {
  control: Control<FutureShipmentFilterFormValues, object>;
  setValue: UseFormSetValue<FutureShipmentFilterFormValues>;
};

export const FutureShipmentChips = (props: FutureShipmentChipsProps) => {
  const { t } = useTranslation();
  const { control, setValue } = props;

  const lesseeInn = useWatch({ control, name: 'lesseeInn' });
  const dealerInn = useWatch({ control, name: 'dealerInn' });
  const quotaOwnerId = useWatch({ control, name: 'quotaOwnerId' });
  const isShipped = useWatch({ control, name: 'isShipped' });
  const isPartiallyShipped = useWatch({ control, name: 'isPartiallyShipped' });

  const onLesseeInnRemove = useCallback(
    (id: string) => {
      const inns = lesseeInn?.filter((v) => v.lesseeInn !== id);
      setValue('lesseeInn', inns);
    },
    [lesseeInn, setValue]
  );

  const onDealerInnRemove = useCallback(
    (id: string) => {
      const inns = dealerInn?.filter((v) => v.dealerInn !== id);
      setValue('dealerInn', inns);
    },
    [dealerInn, setValue]
  );

  const onQuotaOwnerIdRemove = useCallback(
    (id: string) => {
      const ids = quotaOwnerId?.filter((v) => v.id !== id);
      setValue('quotaOwnerId', ids);
    },
    [quotaOwnerId, setValue]
  );

  const onShippedRemove = useCallback(() => {
    setValue('isShipped', false);
  }, [setValue]);

  const onPartiallyShippedRemove = useCallback(() => {
    setValue('isPartiallyShipped', false);
  }, [setValue]);

  const lesseeInnChips = lesseeInn?.map((lessee) => {
    return (
      <Chip
        label={lessee.name}
        id={lessee.lesseeInn}
        key={`lesseeInn-${lessee.lesseeInn}`}
        onRemove={onLesseeInnRemove}
      />
    );
  });

  const dealerInnChips = dealerInn?.map((dealer) => {
    return (
      <Chip
        label={dealer.name}
        id={dealer.dealerInn}
        key={`dealerInn-${dealer.dealerInn}`}
        onRemove={onDealerInnRemove}
      />
    );
  });

  const ownerIdChips = quotaOwnerId?.map((quotaOwner) => {
    return (
      <Chip
        label={quotaOwner.name}
        id={quotaOwner.id}
        key={`quotaOwnerId-${quotaOwner.id}`}
        onRemove={onQuotaOwnerIdRemove}
      />
    );
  });

  const shippedChips = isShipped && (
    <Chip label={t('Shipped')} id={'isShipped'} key={'isShipped'} onRemove={onShippedRemove} />
  );

  const partiallyShippedChips = isPartiallyShipped && (
    <Chip
      label={t('Partially shipped')}
      id={'isPartiallyShipped'}
      key={'isPartiallyShipped'}
      onRemove={onPartiallyShippedRemove}
    />
  );

  return (
    <>
      {shippedChips}
      {partiallyShippedChips}
      {lesseeInnChips}
      {dealerInnChips}
      {ownerIdChips}
    </>
  );
};
