import React, { useMemo } from 'react';
import { Input } from 'components';
import { NumberFormatCustom } from './NumberFormatCustom';
import { FixedAmountFields, AmountTypeContext, FixeAmountInputProps } from './types';
import { createStyles, InputAdornment, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AmountType } from 'schema/serverTypes';
import { MAX_PERCENTS_VAL, MAX_VAL } from './utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      flex: 1,
      minWidth: theme.spacing(4),
      minHeight: 29,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.secondary.light,
      border: '3px solid transparent',
      marginRight: 3,
      borderRadius: 2,
      '.Mui-disabled &': {
        color: theme.palette.text.secondary,
      },
      '&$lockedIcon': {
        backgroundColor: 'transparent',
        cursor: 'pointer',
      },
    },
    lockedIcon: {},
  })
);

export function FixedAmountInput<T extends FixedAmountFields>(props: FixeAmountInputProps<T>) {
  const classes = useStyles();

  const {
    name,
    label,
    disabled = false,
    amountMode,
    fractionDigits,
    icon,
    inputProps,
    required = false,
    allowZero = false,
    min = 0,
    max,
  } = props;

  const context = useMemo(() => {
    return {
      amountType: amountMode,
      fractionDigits,
    };
  }, [amountMode, fractionDigits]);

  const { t } = useTranslation();

  const greaterThanZeroErrorMessage = t('GreaterThanZeroErrorMessage');
  const isRequiredMessage = t('IsRequiredMessage');

  const type = amountMode ?? AmountType.Money;
  const maxValue = max !== undefined ? max : type === AmountType.Money ? MAX_VAL : MAX_PERCENTS_VAL;

  const greaterThanMaxErrorMessage = t('GreaterThanMaxErrorMessage', {
    max: maxValue,
  });

  const greaterThanMaxPercentsErrorMessage = t('GreaterThanMaxPercentsErrorMessage', {
    max: maxValue,
  });

  const rules = useMemo(() => {
    return {
      required: required ? { value: true, message: isRequiredMessage } : undefined,
      min: { value: min, message: greaterThanZeroErrorMessage },
      max: {
        value: maxValue,
        message:
          type === AmountType.Percents
            ? greaterThanMaxPercentsErrorMessage
            : greaterThanMaxErrorMessage,
      },
      validate: {
        zero: (value: any) => (!allowZero && value === 0 ? greaterThanZeroErrorMessage : undefined),
      },
    };
  }, [
    required,
    min,
    type,
    maxValue,
    allowZero,
    greaterThanZeroErrorMessage,
    isRequiredMessage,
    greaterThanMaxErrorMessage,
    greaterThanMaxPercentsErrorMessage,
  ]);

  return (
    <>
      <AmountTypeContext.Provider value={context}>
        <Input
          name={name}
          disabled={disabled}
          label={label}
          rules={rules}
          InputProps={{
            inputComponent: NumberFormatCustom,
            endAdornment: icon ? (
              <InputAdornment position="end" disableTypography className={classes.icon}>
                {icon}
              </InputAdornment>
            ) : null,
          }}
          inputProps={{ ...inputProps }}
        />
      </AmountTypeContext.Provider>
    </>
  );
}
