import { useTranslation } from 'react-i18next';
import { Table, TableCell, TableHead, TableRow, TableBody, IconButton } from '@material-ui/core';
import { LeasingProductItemViewModel } from 'schema/serverTypes';
import { useTableStyles } from '../useTableStyles';
import { Link } from 'react-router-dom';
import { IconSprite } from '../../icons';
import { themeOrange as theme } from '../../../theme';
import { formatPercents } from 'components/utils';

export type LeasingProductsTableProps = {
  rows: LeasingProductItemViewModel[];
  loading: boolean;
};

type TableItemProps = {
  item: LeasingProductItemViewModel;
};

const TableItem = (props: TableItemProps) => {
  const classes = useTableStyles();
  const { item } = props;

  return (
    <TableRow>
      <TableCell size="medium">{item.name}</TableCell>
      <TableCell size="medium">{formatPercents(item.specialCof ?? undefined)}</TableCell>
      <TableCell size="medium">{item.brands.join(', ')}</TableCell>
      <TableCell size="medium" className={classes.actions}>
        <Link
          to={{
            pathname: `/dictionaries/leasingProducts/${item.id}`,
            state: {
              leasingProductItem: item,
            },
          }}
        >
          <IconButton aria-label="edit">
            <IconSprite
              width="14px"
              color={theme.palette.text.primary}
              hoverColor={theme.palette.primary.main}
              icon="edit"
            />
          </IconButton>
        </Link>
      </TableCell>
    </TableRow>
  );
};

export const LeasingProductsTable = (props: LeasingProductsTableProps) => {
  const classes = useTableStyles();
  const { rows } = props;
  const { t } = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell size="medium">{t('Name')}</TableCell>
          <TableCell size="medium">Особый КОФ, %</TableCell>
          <TableCell size="medium">Марка (бренд)</TableCell>
          <TableCell className={classes.change} size="medium"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((item: LeasingProductItemViewModel, index: number) => (
          <TableItem key={index} item={item} />
        ))}
      </TableBody>
    </Table>
  );
};
