import { Typography } from '@material-ui/core';
import { Grid, Button } from 'components';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(5.5),
    },
    nomenclature: {
      color: theme.palette.common.black,
      marginBottom: theme.spacing(1),
    },
    button: {
      display: 'block',
      margin: '4px auto 0',
    },
    required: {
      marginTop: theme.spacing(-1.5),
      color: theme.palette.text.primary,
    },
    actions: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    actionButton: {
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
  })
);

export type Send1CFormProps = {
  quotaId: number;
  onClose?: (...event: any[]) => void;
  handleSendTo1C: () => Promise<void>;
};

export const Send1CForm = (props: Send1CFormProps) => {
  const classes = useStyles();
  const { onClose, handleSendTo1C } = props;
  const { t } = useTranslation();

  return (
    <>
      <Typography variant={'h2'} className={classes.title}>
        По расчету произошли изменения. Отправить расчет в 1С?
      </Typography>
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item md={24} xs={24}>
          <div className={classes.actions}>
            <Button variant="contained" className={classes.actionButton} onClick={handleSendTo1C}>
              {t('Send')}
            </Button>
            <Button variant="outlined" className={classes.actionButton} onClick={onClose}>
              {t('Cancel2')}
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
