import { useTranslation } from 'react-i18next';
import {
  Box,
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import { Empty } from '../../Empty';
import { SupportItem } from './SupportItem';
import { Switch } from '../../form';
import { useForm, useWatch } from 'react-hook-form';
import { SupportTotal } from './SupportTotal';
import { useAssignessReportRequest } from 'services/api/useReportsBackend';
import dayjs from 'dayjs';
import { Button } from '../../Button';
import { useLanguage } from '../../utils/useLanguage';
import { useCallback, useState } from 'react';
import { ShipmentRangeFilterForm } from '../Shipment/ShipmentRangeFilterForm';
import { ModalForm, useModalForm } from '../../Modal';
import { endOfMonth, startOfMonth } from '../Shipment/dates';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: theme.spacing(1.5, 2.5),
      height: '100%',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      height: 30.5,
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
    },
    tabs: {
      marginBottom: -2,
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr 64px 96px auto',
      alignItems: 'center',
      '& > div:first-of-type': {
        gridColumn: 2,
      },
    },
    headTable: {
      borderBottom: 0,
    },
    head: {
      fontSize: 12,
      fontWeight: 400,
      padding: 6,
      whiteSpace: 'nowrap',
      '&.MuiTableCell-root:first-child': {
        padding: 6,
      },
      '&.MuiTableCell-root:last-child': {
        padding: 6,
      },
    },
    name: {
      fontWeight: 600,
      fontSize: 12,
      padding: theme.spacing(0.75),
      marginRight: theme.spacing(2),
      '&': {
        paddingLeft: 6,
      },
    },
    border: {
      '&.MuiTable-root': {
        borderCollapse: 'initial',
      },
    },
    button: {
      marginRight: theme.spacing(2),
      borderRadius: 5,
      lineHeight: 1.1,
      padding: theme.spacing(0.5, 1),
      fontWeight: 400,
      border: `1px solid ${theme.palette.secondary.main}`,
      '&:hover': {
        border: `1px solid ${theme.palette.secondary.main}`,
      },
    },
    filter: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    switch: {
      marginRight: theme.spacing(1),
      color: theme.palette.text.primary,
    },
  }),
);

export const SupportContainer = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const lang = useLanguage();

  const { control } = useForm({
    mode: 'onBlur',
    defaultValues: {
      inWork: true,
    },
  });

  const isInProgress = useWatch({
    name: 'inWork',
    control,
  });

  const switchLabel = isInProgress ? t('Working') : t('Fulfilled');

  const { onOpen, onClose, open } = useModalForm();
  const [from, setFrom] = useState(startOfMonth);
  const [to, setTo] = useState(endOfMonth);
  const [startDate, setStartDate] = useState<string | undefined>(startOfMonth);
  const [endDate, setEndDate] = useState<string | undefined>(endOfMonth);

  const customRangeHandler = useCallback(
    (from, to) => {
      setStartDate(dayjs(from).toISOString());
      setEndDate(dayjs(to).toISOString());
      setFrom(from);
      setTo(to);
    },
    [setStartDate, setEndDate],
  );

  const { data: assignees = [], isLoading } = useAssignessReportRequest(
    isInProgress,
    startDate,
    endDate,
  );
  const data = assignees.map((t) => ({
    id: t.id,
    name: t.name,
    isActive: t.isActive,
    contracts: [t.contractsCount.total, t.contractsCount.isLongProgress],
    payment: [t.paymentsCount.total, t.paymentsCount.isLongProgress],
    telematics: [t.telematicsCount.total, t.telematicsCount.isLongProgress],
    shipment: [t.shipmentsCount.total, t.shipmentsCount.isLongProgress],
    verification: [t.verificationsCount.total, t.verificationsCount.isLongProgress],
    shipmentReport: [t.shipmentReportsCount.total, t.shipmentReportsCount.isLongProgress],
  }));

  const max =
    data.length > 0
      ? data[0].contracts[0] +
        data[0].payment[0] +
        data[0].telematics[0] +
        data[0].shipment[0] +
        data[0].verification[0] +
        data[0].shipmentReport[0]
      : 0;

  return (
    <>
      <Paper className={classes.root}>
        <Box className={classes.header}>
          <Typography variant="subtitle1">{t('Support Progress')}</Typography>
        </Box>
        {isLoading ? (
          <Empty>{t('Please wait while the data is loading')}</Empty>
        ) : (
          <TableContainer>
            <Table className={classes.border}>
              <TableHead className={classes.headTable}>
                <TableRow>
                  <TableCell className={classes.head}>
                    <form className={classes.filter}>
                      <div className={classes.switch}>
                        <Switch
                          label={switchLabel}
                          name="inWork"
                          control={control}
                          labelOn={''}
                          labelOff={''}
                        />
                      </div>
                      {!isInProgress && (
                        <Button variant={'outlined'} className={classes.button} onClick={onOpen}>
                          {dayjs(from).locale(lang).format('DD MMM YYYY')} -{' '}
                          {dayjs(to).locale(lang).format('DD MMM YYYY')}
                        </Button>
                      )}
                    </form>
                  </TableCell>
                  <TableCell className={classes.head} align="center">
                    {t('Contracts')}
                  </TableCell>
                  <TableCell className={classes.head} align="center">
                    {t('IssueType.Verification')}
                  </TableCell>
                  <TableCell className={classes.head} align="center">
                    {t('Payment')}
                  </TableCell>
                  <TableCell className={classes.head} align="center">
                    {t('Telematics')}
                  </TableCell>
                  <TableCell className={classes.head} align="center">
                    {t('Act request')}
                  </TableCell>
                  <TableCell className={classes.head} align="center">
                    {t('IssueType.ShipmentReport')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item) => (
                  <SupportItem key={item.id} {...item} max={max} />
                ))}
                <SupportTotal />
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
      <ModalForm open={open} onClose={onClose} width={980}>
        <ShipmentRangeFilterForm
          from={from}
          to={to}
          customRangeHandler={customRangeHandler}
          onClose={onClose}
        />
      </ModalForm>
    </>
  );
};
