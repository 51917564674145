import { ReactElement, createContext, Dispatch, SetStateAction } from 'react';
import { AmountType } from 'schema/serverTypes';
import { OutlinedInputProps } from '@material-ui/core';
import { CalculationFormValues } from 'components/calculator/types';
import { FieldPathByValue, WithoutSufix } from 'components/form';

export type AmountTypeContextProps = {
  amountType: AmountType;
  fractionDigits?: number;
};

export const AmountTypeContext = createContext<AmountTypeContextProps>({
  amountType: AmountType.Percents,
});

export type AmountTypeFields = FieldPathByValue<CalculationFormValues, AmountType>;

export type AmountValidationProps = {
  required?: boolean;
  min?: number;
  max?: number;
  maxPercents?: number;
  allowZero?: boolean;
  amountMode?: AmountType;
};

export type FixedAmountFields = FieldPathByValue<CalculationFormValues, number | undefined>;

export type AmountFields = WithoutSufix<AmountTypeFields>;

type GeneralInputProps = AmountValidationProps & {
  label?: string;
  disabled?: boolean;
  fractionDigits?: number;
  useSaleCurrency?: boolean;
  inputProps?: OutlinedInputProps['inputProps'];
};

export type AmountTypeProps<T extends AmountTypeFields> = {
  name: T;
};

export type AmountInputProps<T extends AmountFields> = GeneralInputProps &
  AmountValidationProps & {
    name: T;
    switchDisabled?: boolean;
  };

export type FixeAmountInputProps<T extends FixedAmountFields> = GeneralInputProps &
  AmountValidationProps & {
    name: T;
    amountMode: AmountType;
    icon?: ReactElement | string;
    isLockedButton?: boolean;
    setIsCofDisabled?: Dispatch<SetStateAction<boolean>>;
  };
