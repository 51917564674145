import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box, MenuItem } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { Input, Button, Switch } from 'components';
import { Grid } from 'components/Grid';
import { useTranslation } from 'react-i18next';
import { Tabs, useTabs } from '../Tabs';
import { useController, useWatch } from 'react-hook-form';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { useCurrentUserTabsQuery } from 'services/api/useApplicationUsersBackend';
import { FutureShipmentFilterFormProps } from './types';
import { FutureShipmentChips } from './FutureShipmentChips';
import { FutureShipmentLesseeAutocomplete } from './FutureShipmentLesseeAutocomplete';
import { FutureShipmentDealerAutocomplete } from './FutureShipmentDealerAutocomplete';
import { QuotaOwnerAutocomplete } from './QuotaOwnerAutocomplete';
import { useUserRole } from '../../services';
import { ApplicationUsersTab, ApplicationUserTab } from '../../schema';
import { FormSelect } from '../form/FormSelect';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '100%',
    },
    filterContainer: {
      width: 'calc(100vw - 260px)',
      overflow: 'hidden',
    },
    buttonReset: {
      display: 'flex',
      alignItems: 'flex-end',
      marginBottom: theme.spacing(0.2),
    },
  })
);

export const FutureShipmentFilterForm = (props: FutureShipmentFilterFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { control, onReset, setValue } = props;
  const {
    field: { onChange: onTabIdChange },
  } = useController({ control, name: 'tabId' });

  const { data: userTabs = [] } = useCurrentUserTabsQuery();
  const { isInsideSales } = useUserRole();

  type ApplicationUsersTabByRole = Pick<ApplicationUsersTab, 'name' | 'id'>;

  const userTabsIS = [
    { name: t('Mine'), id: ApplicationUserTab.mine },
    { name: t('Incoming'), id: ApplicationUserTab.incoming },
    { name: t('All'), id: ApplicationUserTab.all },
  ];
  const tabsByRole: ApplicationUsersTabByRole[] = isInsideSales ? userTabsIS : userTabs;

  const tabsProps = useTabs(
    tabsByRole.map((t: ApplicationUsersTabByRole) => t.name),
    0
  );

  const { tabIndex, onChangeTab, tabs } = tabsProps;

  const onMineChanged = useCallback(
    (e: any, index: number) => {
      onChangeTab(e, index);
      const tab = tabsByRole.find((t) => t.name === tabs[index]);
      if (tab) {
        onTabIdChange(tab.id);
      }
    },
    [onChangeTab, onTabIdChange, tabs, tabsByRole]
  );

  const isPartiallyShipped = useWatch({ control, name: 'isPartiallyShipped' }) ?? false;
  const isShipped = useWatch({ control, name: 'isShipped' }) ?? false;

  const value = useMemo(() => {
    return isShipped && isPartiallyShipped
      ? ['isShipped', 'isPartiallyShipped']
      : isShipped
      ? ['isShipped']
      : isPartiallyShipped
      ? ['isPartiallyShipped']
      : [];
  }, [isPartiallyShipped, isShipped]);

  const onOtherChanged = useCallback(
    (
      event: ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>
    ) => {
      const flags = event.target.value as string[] | undefined;
      setValue('isShipped', flags?.includes('isShipped'));
      setValue('isPartiallyShipped', flags?.includes('isPartiallyShipped'));
    },
    [setValue]
  );

  return (
    <form>
      <Box mb={2.5} display="flex" justifyContent="space-between" alignItems="flex-end">
        <Box>
          <Tabs {...tabsProps} value={tabIndex} onChangeTab={onMineChanged} />
        </Box>
        <Box>
          <Switch
            label={`${t('Working')}`}
            name="isInWork"
            control={control}
            labelOn={''}
            labelOff={''}
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mb={2.5} alignItems="flex-end">
        <Box flex={1}>
          <Grid container columnSpacing={2} rowSpacing={2.5} className={classes.root}>
            <Grid md={3} xs={24} item>
              <FormSelect
                label={t('Other')}
                variant="standard"
                value={value}
                multiple
                onChange={onOtherChanged}
              >
                <MenuItem key="isShipped" value="isShipped">
                  {t('Shipped')}
                </MenuItem>
                <MenuItem key="isPartiallyShipped" value="isPartiallyShipped">
                  {t('Partially shipped')}
                </MenuItem>
              </FormSelect>
            </Grid>
            <Grid md={3} xs={24} item>
              <Input
                label={t('Contract number')}
                control={control}
                name="contractNumber"
                variant="standard"
              />
            </Grid>
            <Grid md={4} xs={24} item>
              <FutureShipmentLesseeAutocomplete control={control} />
            </Grid>
            <Grid md={4} xs={24} item>
              <FutureShipmentDealerAutocomplete control={control} />
            </Grid>
            <Grid md={4} xs={24} item>
              <QuotaOwnerAutocomplete control={control} />
            </Grid>
            <Grid md={4} xs={24} item>
              <Input
                label={`Номер расчета`}
                control={control}
                name="quotaNumber"
                variant="standard"
              />
            </Grid>
            <Grid md={2} xs={24} item className={classes.buttonReset}>
              <FormControl>
                <Button variant="text" onClick={onReset}>
                  {t('Reset')}
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box mb={1}>
        <FutureShipmentChips control={control} setValue={setValue} />
      </Box>
    </form>
  );
};
