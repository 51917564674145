import { Divider, Typography } from '@material-ui/core';
import { Grid } from 'components/Grid';
import { useCallback, useEffect } from 'react';
import { Control, useFieldArray, UseFormSetValue, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button } from 'components';
import { ContractPayConditionSelectItem, ContractPayConditionType } from 'schema/serverTypes';
import { ContractFormValues } from '../types';
import { PaymentTermFields } from './PaymentTermFields';
import { UseFormClearErrors, UseFormSetError } from 'react-hook-form/dist/types/form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    add: {
      color: theme.palette.primary.light,
    },
    wrapper: {
      width: '100%',
    },
    title: {
      fontWeight: 700,
      marginBottom: theme.spacing(1.5),
    },
    right: {
      justifySelf: 'end',
    },
    divider: {
      backgroundColor: theme.palette.background.default,
    },
  })
);

type PaymentTermArrayProps = {
  control: Control<ContractFormValues, any>;
  setError: UseFormSetError<ContractFormValues>;
  clearErrors: UseFormClearErrors<ContractFormValues>;
  setValue?: UseFormSetValue<ContractFormValues>;
  type: ContractPayConditionType;
  selectItems: ContractPayConditionSelectItem[];
  validatePayConditions: () => boolean;
  sum: number;
};

export const PaymentTermArray = (props: PaymentTermArrayProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { control, type, selectItems, clearErrors, setError, validatePayConditions, sum } = props;
  let blockName:
    | 'advancePayConditionBlock'
    | 'prepaymentPayConditionBlock'
    | 'postpaymentPayConditionBlock';
  switch (type) {
    case ContractPayConditionType.Advance:
      blockName = 'advancePayConditionBlock';
      break;
    case ContractPayConditionType.Prepayment:
      blockName = 'prepaymentPayConditionBlock';
      break;
    case ContractPayConditionType.Postpayment:
      blockName = 'postpaymentPayConditionBlock';
      break;
  }

  let title: string;
  switch (type) {
    case ContractPayConditionType.Advance:
      title = 'Аванс';
      break;
    case ContractPayConditionType.Prepayment:
      title = 'Предоплата';
      break;
    case ContractPayConditionType.Postpayment:
      title = 'Постоплата';
      break;
  }

  const { fields, append, remove } = useFieldArray({
    control,
    name: `${blockName}.payConditions`,
  });

  const onAdd = useCallback(() => {
    const head: any = {
      //ContractPayConditionViewModel
      type,
      payment: 0,
      dayCount: 5,
    };
    append(head);
  }, [append, type]);

  const conditions = useWatch({ control, name: `${blockName}.payConditions` });
  const calculatePayConditionsSum = () => {
    return conditions.reduce((sum, item) => sum + (parseInt(item.payment.toString(), 10) || 0), 0);
  };

  const payConditionsSum = calculatePayConditionsSum();

  const validateSum = useCallback(() => {
    let isValid = true;
    if (payConditionsSum !== sum) {
      isValid = false;
      conditions.forEach((_, index) => {
        setError(`${blockName}.payConditions.${index}.payment`, {
          type: 'manual',
          message: `Сумма процентов платежа в блоке должна быть ${sum}!`,
        });
      });
    } else {
      clearErrors(`${blockName}.payConditions`);
    }

    return isValid;
  }, [clearErrors, setError, blockName, conditions, payConditionsSum, sum]);

  useEffect(() => {
    validateSum();
  }, [validateSum]);

  return (
    <div className={classes.wrapper}>
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item xs={24}>
          <Typography variant={'body2'} className={classes.title}>
            {title}
          </Typography>
        </Grid>
      </Grid>
      {fields.map((item, index) => {
        return (
          <PaymentTermFields
            key={item.id}
            item={item}
            index={index}
            remove={remove}
            control={control}
            blockName={`${blockName}.payConditions`}
            subType={selectItems[0].subType}
            description={selectItems[0].description}
            text={selectItems[0].text}
            validatePayConditions={validatePayConditions}
            validateSum={validateSum}
            sum={sum}
          />
        );
      })}
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item xs={24} className={classes.right}>
          <Button variant="text" className={classes.add} onClick={onAdd}>
            <Typography variant="subtitle1" color={'primary'}>
              + {t('Add')}
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
    </div>
  );
};
