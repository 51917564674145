import { Grid, Select, StaticInput } from 'components';
import { useTelematicsProvidersQuery } from 'services';
import { CounterpartyTelematicsDeviceViewModel, Telematics } from 'schema/serverTypes';
import { MenuItem } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { ContractFormValues } from './types';
import { useTranslation } from 'react-i18next';
import { useTelematicsName } from 'services/api/useTelematicsDictionaryBackend';

type TelematicsProps = {
  type?: Telematics;
  control: Control<ContractFormValues, any>;
  setValue: UseFormSetValue<ContractFormValues>;
};

export const TelematicsFields = (props: TelematicsProps) => {
  const { t } = useTranslation();

  const { type, control, setValue } = props;
  const { data = [] } = useTelematicsProvidersQuery();

  const telematicsProviderObj = useWatch({ control, name: 'telematicsProvider' });
  const telematicsDeviceObj = useWatch({ control, name: 'telematicsDevice' });

  const [devices, setDevices] = useState<CounterpartyTelematicsDeviceViewModel[]>([]);

  const telematicsProviderHandler = useCallback(
    (value: string) => {
      const provider = data.find((item) => item.inn === value);
      setDevices(provider?.telematicsDevices ?? []);
      if (provider?.defaultTelematicsDeviceId) {
        setValue(
          'telematicsDeviceId',
          provider?.telematicsDevices.find(
            (item) => item.id === provider?.defaultTelematicsDeviceId
          )?.id
        );
      }
    },
    [data, setValue]
  );

  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {
    if (!isInitialized && telematicsDeviceObj && telematicsProviderObj && data?.length) {
      setValue('telematicsProviderInn', telematicsProviderObj.inn);
      const provider = data.find((item) => item.inn === telematicsProviderObj.inn);
      setDevices(provider?.telematicsDevices ?? []);
      setValue('telematicsDeviceId', telematicsDeviceObj.id);
      setIsInitialized(true);
    }
  }, [telematicsProviderObj, telematicsDeviceObj, setValue, data, isInitialized]);

  const value = useTelematicsName(type);

  return (
    <Grid container columnSpacing={2} rowSpacing={2.5}>
      {type === Telematics.None || type === Telematics.Client ? (
        <Grid md={24} xs={24} item>
          <StaticInput label={t('Name')} value={value} />
        </Grid>
      ) : (
        <>
          <Grid md={8} xs={24} item>
            <StaticInput label={t('Name')} value={value} />
          </Grid>
          <Grid md={8} xs={24} item>
            <Select
              label={t('Vendor')}
              name="telematicsProviderInn"
              control={control}
              onChangeCallback={telematicsProviderHandler}
              defaultValue={telematicsProviderObj?.inn}
              // rules={{
              //   required: {
              //     value: true,
              //     message: 'Необходимо выбрать поставщика телематики',
              //   },
              // }}
            >
              {data.map((provider) => (
                <MenuItem key={provider.inn} value={provider.inn}>
                  {provider.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid md={8} xs={24} item>
            <Select
              label={t('Device')}
              name="telematicsDeviceId"
              control={control}
              defaultValue={telematicsDeviceObj?.id.toString()}
              // rules={{
              //   required: {
              //     value: true,
              //     message: 'Необходимо выбрать устройтсво телематики',
              //   },
              // }}
            >
              {devices.map((device) => (
                <MenuItem key={device.id} value={device.id.toString()}>
                  {device.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </>
      )}
    </Grid>
  );
};
