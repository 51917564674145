import { useTranslation } from 'react-i18next';
import { FixedAmountInput } from '../AmountInput';
import { useCalculationFormContext } from '../types';
import { useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import { AmountType } from 'schema/serverTypes';

export const SaleTotalPriceInput = () => {
  const { control, setValue, getValues } = useCalculationFormContext();
  const nomeclatures = useWatch({ control, name: 'nomenclatures' });

  useEffect(() => {
    const totalPrice = nomeclatures.reduce(
      (prev, next) => prev + (next.price ?? 0) * next.count,
      0
    );
    const currentTotalPrice = getValues('itemPrice') ?? 0;
    if (currentTotalPrice !== totalPrice) {
      setValue('itemPrice', totalPrice);
    }
  }, [nomeclatures, getValues, setValue]);

  const { t } = useTranslation();

  return (
    <FixedAmountInput
      name="itemPrice"
      label={t('TotalDKPCost')}
      amountMode={AmountType.Money}
      disabled
    />
  );
};
