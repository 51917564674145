//@ts-ignore
import { AmountFields, AmountInputProps, AmountTypeContext } from './types';
import { Input } from 'components/form/Input';
import { AmountType } from 'schema';
import { useCallback, useEffect, useMemo } from 'react';
import { NumberFormatCustom } from './NumberFormatCustom';
import { AmountTypeButton } from './AmountTypeButton';
import { useCalculationFormContext } from '../types';
import { useController, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MAX_PERCENTS_VAL, MAX_VAL } from './utils';
import { formatNumber } from '../../../utils';

export function AmountInput<T extends AmountFields>(props: AmountInputProps<T>) {
  const {
    name,
    disabled,
    fractionDigits,
    label,
    inputProps,
    max,
    maxPercents,
    min = 0,
    allowZero = true,
    required = false,
    useSaleCurrency,
    switchDisabled = false,
  } = props;

  const { control, setValue, clearErrors } = useCalculationFormContext();
  const {
    field: { onChange },
  } = useController({ control, name: `${name}.value` });

  const type =
    (useWatch({ control, name: `${name}.type` }) as AmountType | undefined) ?? AmountType.Percents;

  const context = useMemo(() => {
    return {
      amountType: type,
      fractionDigits,
    };
  }, [type, fractionDigits]);

  const resetValue = useCallback(() => {
    setValue(`${name}.value`, 0 as any);
    clearErrors(`${name}.value`);
  }, [setValue, name, clearErrors]);

  const { t } = useTranslation();

  const greaterThanZeroErrorMessage = t('GreaterThanZeroErrorMessage');
  const lessThan100ErrorMessage = t('LessThan100ErrorMessage');
  const isRequiredMessage = t('IsRequiredMessage');

  const maxValue =
    (type === AmountType.Money ? max : maxPercents) ??
    (type === AmountType.Money ? MAX_VAL : MAX_PERCENTS_VAL);

  const greaterThanMaxErrorMessage = t('GreaterThanMaxErrorMessage', {
    max: formatNumber(maxValue, 0, false),
  });

  const rules = useMemo(() => {
    return {
      required: { value: required ?? true, message: isRequiredMessage },
      min: { value: min, message: greaterThanZeroErrorMessage },
      max: {
        value: maxValue,
        message:
          type === AmountType.Percents && maxValue === 100
            ? lessThan100ErrorMessage
            : greaterThanMaxErrorMessage,
      },
      validate: {
        zero: (value: any) => (!allowZero && value === 0 ? greaterThanZeroErrorMessage : undefined),
      },
    };
  }, [
    required,
    min,
    type,
    maxValue,
    allowZero,
    greaterThanZeroErrorMessage,
    lessThan100ErrorMessage,
    isRequiredMessage,
    greaterThanMaxErrorMessage,
  ]);

  const value1 = useWatch({ control, name: `${name}.value` }) as any;

  useEffect(() => {
    if (value1 === '' || (!allowZero && value1 === 0)) {
      setValue(`${name}.value`, 0 as any);
    }
  }, [value1, name, setValue, allowZero]);

  return (
    <AmountTypeContext.Provider value={context}>
      <Input
        name={`${name}.value`}
        disabled={disabled}
        label={label}
        onChange={onChange}
        InputProps={{
          inputComponent: NumberFormatCustom,
          endAdornment: (
            <AmountTypeButton
              name={`${name}.type`}
              disabled={disabled || switchDisabled}
              useSaleCurrency={useSaleCurrency}
              resetValue={resetValue}
            />
          ),
        }}
        inputProps={inputProps}
        rules={rules}
      />
    </AmountTypeContext.Provider>
  );
}
