import {
  SortOrder,
  Telematics,
  TelematicsDictionaryItemSortBy,
  TelematicsDictionaryItemViewModel,
  UpdateTelematicsRequestViewModel,
} from 'schema/serverTypes';
import { useDictionaryBackendQuery, useDictionaryBackendMutation } from './useQuotasBackend';
import { MutationOptions, QueryOptions } from './useBackend';
import { useTranslation } from 'react-i18next';

type TelematicsDictionaryQueryRequest = {
  sortBy: TelematicsDictionaryItemSortBy;
  order: SortOrder;
};

export const useTelematicsDictionaryQuery = (
  request: TelematicsDictionaryQueryRequest,
  options?: QueryOptions<TelematicsDictionaryItemViewModel[], string>
) => {
  const { sortBy, order } = request;
  let searchParams = new URLSearchParams();

  searchParams.append('sortBy', sortBy);
  searchParams.append('order', order);

  return useDictionaryBackendQuery<TelematicsDictionaryItemViewModel[]>(
    `telematics?${searchParams}`,
    options ?? {}
  );
};

export const useUpdateTelematicsDictionaryMutation = (
  id: number,
  options?: MutationOptions<UpdateTelematicsRequestViewModel, TelematicsDictionaryItemViewModel>
) =>
  useDictionaryBackendMutation<UpdateTelematicsRequestViewModel, TelematicsDictionaryItemViewModel>(
    `telematics/${id.toString()}`,
    { method: 'PUT', ...options }
  );

export const useTelematicsName = (telematics?: Telematics) => {
  const { t } = useTranslation();

  const { data: telematicsDictionary = [] } = useTelematicsDictionaryQuery(
    {
      sortBy: TelematicsDictionaryItemSortBy.Name,
      order: SortOrder.asc,
    },
    {
      enabled: !!telematics,
    }
  );

  if (!telematics) {
    return t('Unknown');
  }

  const telematicsItem = telematicsDictionary.find((item) => item.telematics === telematics);

  if (telematicsItem) {
    return telematicsItem.name;
  }

  const getTelematicsType = (telematics: Telematics) => {
    switch (telematics) {
      case Telematics.Caesar:
        return t(`TelematicsType.caesar`);
      case Telematics.XPro:
        return t(`TelematicsType.xpro`);
      case Telematics.Krimistop:
        return t(`TelematicsType.krimistop`);
      case Telematics.XproKrimistop:
        return t(`TelematicsType.xproKrimistop`);
      case Telematics.Client:
        return t(`TelematicsType.client`);
      case Telematics.Local:
        return t(`TelematicsType.local`);
      case Telematics.LocalNoCommission:
        return t(`TelematicsType.localNoCommission`);
      case Telematics.None:
        return t(`TelematicsType.none`);
      default:
        return 'unknown telematics';
    }
  };

  return getTelematicsType(telematics);
};
