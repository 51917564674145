import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  ShipmentItemViewModel,
  CounterpartyViewModel,
  ShipmentItemHead,
  ShipShipmentItemViewModel,
} from 'schema/serverTypes';
import {
  useCounterpartiesBackendQuery,
  useShipShipmentItemMutation,
  useUpdateShipmentItemMutation as updateItemMutation,
} from 'services/api';
import { ShipmentItemFormValues } from './types';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../Toast';
import { useQueryClient } from 'react-query';

const useDefaultValues = (itemModel: ShipmentItemViewModel) => {
  const {
    id,
    quotaId,
    expectedShipmentDate = '',
    shipmentDate = '',
    brand,
    category,
    itemModel: model,
    leaseSubject = '',
    leaseSubjectInDocument = '',
    shipmentPlace = '',
    vin = '',
    engineNumber = '',
    chassisNumber = '',
    bodyNumber = '',
    color = '',
    enginePower = '',
    engineVolume = '',
    technicalDevicePassport,
    dealerContact,
    lesseeContact,
    lessorContact,
    isLocked,
    fileUrl = '',
    file,
  } = itemModel;

  const nomenclature = `${leaseSubject} ${brand} ${model}`;

  const values: ShipmentItemFormValues = useMemo(() => {
    return {
      id,
      quotaId,
      expectedShipmentDate,
      shipmentDate,
      brand,
      category,
      itemModel: model,
      leaseSubject,
      leaseSubjectInDocument,
      shipmentPlace,
      vin,
      engineNumber,
      chassisNumber,
      bodyNumber,
      color,
      enginePower,
      engineVolume,
      technicalDevicePassport: {
        number: technicalDevicePassport?.number ?? 0,
        issuedBy: technicalDevicePassport?.issuedBy ?? '',
        issuedDate: technicalDevicePassport?.issuedDate ?? '',
        price: technicalDevicePassport?.price ?? 0,
      },
      dealerContact: {
        id: dealerContact?.id ?? 0,
        number: dealerContact?.number ?? '',
        lastName: dealerContact?.lastName ?? '',
        firstName: dealerContact?.firstName ?? '',
        middleName: dealerContact?.middleName ?? '',
        reason: dealerContact?.reason ?? '',
        date: dealerContact?.date ?? '',
      },
      lesseeContact: {
        id: lesseeContact?.id ?? 0,
        number: lesseeContact?.number ?? '',
        lastName: lesseeContact?.lastName ?? '',
        firstName: lesseeContact?.firstName ?? '',
        middleName: lesseeContact?.middleName ?? '',
        reason: lesseeContact?.reason ?? '',
        date: lesseeContact?.date ?? '',
      },
      lessorContact: {
        id: lessorContact?.id ?? 0,
        number: lessorContact?.number ?? '',
        lastName: lessorContact?.lastName ?? '',
        firstName: lessorContact?.firstName ?? '',
        middleName: lessorContact?.middleName ?? '',
        reason: lessorContact?.reason ?? '',
        date: lessorContact?.date ?? '',
      },
      isLocked,
      fileUrl,
      file: {
        downloadUrl: file?.downloadUrl ?? '',
        fileName: file?.fileName ?? '',
        contentType: file?.contentType ?? '',
      },
      nomenclature,
    };
  }, [
    id,
    quotaId,
    expectedShipmentDate,
    shipmentDate,
    brand,
    category,
    model,
    leaseSubject,
    leaseSubjectInDocument,
    shipmentPlace,
    vin,
    engineNumber,
    chassisNumber,
    bodyNumber,
    color,
    enginePower,
    engineVolume,
    technicalDevicePassport,
    dealerContact,
    lesseeContact,
    lessorContact,
    isLocked,
    fileUrl,
    file,
    nomenclature,
  ]);

  return values;
};

const useShipmentItemMutation = (quotaId: number, shipmentItemId: number) => {
  const { t } = useTranslation();
  const toast = useToast();

  const { mutateAsync } = useShipShipmentItemMutation(quotaId, shipmentItemId, {
    onSuccess: () => {
      toast(t('ShipmentShipSuccessMessage'), 'success');
    },
  });

  const submit = useCallback(
    async (values: ShipShipmentItemViewModel) => {
      return await mutateAsync(values);
    },
    [mutateAsync]
  );

  return submit;
};

const useUpdateShipmentItemMutation = (quotaId: number, shipmentItemId: number) => {
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutateAsync } = updateItemMutation(quotaId, shipmentItemId, {
    onSuccess: () => {
      toast(t('SuccessMessage'), 'success');
      queryClient.invalidateQueries({
        predicate: (query) => {
          return Array.isArray(query.queryKey) && query.queryKey.indexOf('shipment') >= 0;
        },
      });
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const submit = useCallback(
    async (values: ShipShipmentItemViewModel) => {
      return await mutateAsync(values);
    },
    [mutateAsync]
  );

  return submit;
};

export const useShipmentItemForm = (
  quotaId: number,
  item: ShipmentItemViewModel,
  dealer?: string,
  lessee?: string,
  lessor?: string
) => {
  const defaultValues = useDefaultValues(item);

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isSubmitted, isDirty },
    setValue,
    getValues,
    reset,
  } = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const mutateAsync = useShipmentItemMutation(quotaId, item.id);

  const onSubmitShip = useMemo(() => {
    const submit = async (values: ShipmentItemFormValues) => {
      return await mutateAsync(values as any);
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync]);

  const updateAsync = useUpdateShipmentItemMutation(quotaId, item.id);

  const onSubmitUpdate = useMemo(() => {
    const submit = async (values: ShipmentItemFormValues) => {
      return await updateAsync(values as any);
    };
    return handleSubmit(submit);
  }, [handleSubmit, updateAsync]);

  const dealerRequestUrl = `${dealer}`;
  const { data: counterpartyDealer, isLoading: isDealerHeadsLoading } =
    useCounterpartiesBackendQuery<CounterpartyViewModel>(dealerRequestUrl, {
      enabled: dealer !== undefined && dealer !== '',
      refetchInterval: false,
      refetchOnWindowFocus: false,
    });

  const dealerHeads = useMemo(() => {
    let options =
      counterpartyDealer?.heads.map(({ lastName = '', ...head }) => {
        const option: ShipmentItemHead = {
          lastName,
          ...head,
        };

        return option;
      }) ?? [];

    if (options.length === 0) {
      if (item.dealerContact !== undefined) {
        options.push({
          ...item.dealerContact,
        });
      }
    }
    return options;
  }, [counterpartyDealer?.heads, item.dealerContact]);

  const lesseeRequestUrl = `${lessee}`;
  const { data: counterpartyLessee, isLoading: isLesseeHeadsLoading } =
    useCounterpartiesBackendQuery<CounterpartyViewModel>(lesseeRequestUrl, {
      enabled: lessee !== undefined && lessee !== '',
      refetchInterval: false,
      refetchOnWindowFocus: false,
    });
  const lesseeHeads = useMemo(() => {
    let options =
      counterpartyLessee?.heads.map(({ lastName = '', ...head }) => {
        const option: ShipmentItemHead = {
          lastName,
          ...head,
        };

        return option;
      }) ?? [];

    if (options.length === 0) {
      if (item.lesseeContact !== undefined) {
        options.push({
          ...item.lesseeContact,
        });
      }
    }
    return options;
  }, [counterpartyLessee?.heads, item.lesseeContact]);

  const lessorRequestUrl = `${lessor}`;
  const { data: counterpartyLessor, isLoading: isLessorHeadsLoading } =
    useCounterpartiesBackendQuery<CounterpartyViewModel>(lessorRequestUrl, {
      enabled: lessor !== undefined && lessor !== '',
      refetchInterval: false,
      refetchOnWindowFocus: false,
      keepPreviousData: false,
    });
  const lessorHeads = useMemo(() => {
    let options =
      counterpartyLessor?.heads.map(({ lastName = '', ...head }) => {
        const option: ShipmentItemHead = {
          lastName,
          ...head,
        };

        return option;
      }) ?? [];

    if (options.length === 0) {
      if (item.lessorContact !== undefined) {
        options.push({
          ...item.lessorContact,
        });
      }
    }
    return options;
  }, [counterpartyLessor?.heads, item.lessorContact]);

  return {
    onSubmitShip,
    onSubmitUpdate,
    control,
    isSubmitting,
    setValue,
    getValues,
    reset,
    isDirty: isDirty && !isSubmitted,
    dealerHeads,
    lesseeHeads,
    lessorHeads,
    isHeadsLoading: isDealerHeadsLoading || isLesseeHeadsLoading || isLessorHeadsLoading,
  };
};
