import { Checkbox as MuiCheckbox, createStyles, makeStyles, Paper, Theme } from '@material-ui/core';
import { Button, IconCheckbox, IconSprite, ModalForm, useModalForm } from 'components';
import { useTranslation } from 'react-i18next';
import { QuotaCalculationResult, ShipmentItemViewModel, Telematics } from 'schema';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { ShipmentItem } from './ShipmentItem';
import { useUserRole } from '../../../services';
import { MultiShipmentReportForm } from './MultiShipmentReportForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panels: {
      marginBottom: (props: any) => (props.isTask ? '0' : '75px'),
    },
    legendList: {
      display: 'flex',
    },
    legendItem: {
      display: 'flex',
      alignItems: 'center',
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
      '&:before': {
        content: '""',
        display: 'inline-block',
        borderRadius: '50%',
        width: 15,
        height: 15,
        marginRight: theme.spacing(1),
      },
      '&.success:before': {
        border: `3px solid ${theme.palette.green.main}`,
      },
      '&.error:before': {
        border: `3px solid ${theme.palette.chartPurple.main}`,
      },
    },
    actions: {
      padding: theme.spacing(2, 2.5),
      marginBottom: theme.spacing(2),
      borderRadius: 0,
      '& > *': {
        marginRight: theme.spacing(2.5),
      },
    },
    multiActionForm: {
      display: 'flex',
      gap: theme.spacing(2.5),
      alignItems: 'center',
    },
    mainDatePicker: {
      width: 280,
    },
  })
);

type MultiShipmentReportProps = {
  quota: QuotaCalculationResult;
  lessor?: string;
  items: ShipmentItemViewModel[];
  isLocked: boolean;
  isLoading: boolean;
  telematics?: Telematics;
  setIsDirty?: (value: boolean) => void;
  isTask?: boolean;
  setCurrentView: Dispatch<SetStateAction<string | null>>;
};

export const MultiShipmentReport = (props: MultiShipmentReportProps) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { quota, lessor, items, isLocked, isLoading, telematics, setIsDirty, setCurrentView } =
    props;
  const { id, isSalesManager, isSuperSalesManager, isInsideSales } = useUserRole();

  const [allChecked, setAllChecked] = useState(false);
  const [itemCheckedStates, setItemCheckedStates] = useState<boolean[]>([]);

  useEffect(() => {
    if (items && items.length > 0) {
      setItemCheckedStates(items.map(() => false));
    }
  }, [items]);

  const handleCheckAll = () => {
    const newCheckedState = !allChecked;
    // Only toggle the states of items that are not disabled
    const updatedCheckedStates = itemCheckedStates.map((checked, index) => {
      const isDisabled =
        !items[index].shipmentReportIssueId &&
        (id === quota.user.id || isSuperSalesManager || isSalesManager || isInsideSales); //  condition for disabling
      return isDisabled ? false : newCheckedState;
    });

    // Set the states
    setAllChecked(newCheckedState);
    setItemCheckedStates(updatedCheckedStates);
  };

  useEffect(() => {
    // Determine if all *enabled* items are checked
    const allItemsChecked = items.every((item, index) => {
      const isDisabled =
        !items[index].shipmentReportIssueId &&
        (id === quota.user.id || isSuperSalesManager || isSalesManager || isInsideSales);
      return isDisabled || itemCheckedStates[index]; // Consider true for disabled items
    });
    setAllChecked(allItemsChecked);
  }, [
    itemCheckedStates,
    items,
    id,
    isInsideSales,
    isSalesManager,
    isSuperSalesManager,
    quota.user.id,
  ]);

  const checkedItemIds = useMemo(() => {
    return items
      .filter((_, index) => itemCheckedStates[index]) // Фильтруем элементы, которые отмечены
      .map((item) => item.id); // Извлекаем id из отмеченных элементов
  }, [itemCheckedStates, items]);

  const { onOpen, onClose, open } = useModalForm();
  const openShipmentReportFormHandler = useCallback(() => {
    onOpen();
  }, [onOpen]);

  return (
    <>
      <Paper className={classes.actions}>
        <div className={classes.multiActionForm}>
          <MuiCheckbox
            checked={allChecked ?? false}
            onChange={handleCheckAll}
            checkedIcon={<IconCheckbox checked={true} />}
            icon={<IconCheckbox checked={false} />}
            color={'primary'}
            size="small"
          />
          <IconSprite icon="textFile" width={24} height={24} />
          <Button
            variant="contained"
            onClick={openShipmentReportFormHandler}
            disabled={checkedItemIds.length === 0}
          >
            {t('Shipment reports')}
          </Button>
          <Button variant="outlined" onClick={() => setCurrentView(null)}>
            {t('Cancel')}
          </Button>
        </div>
      </Paper>
      <div className={classes.panels}>
        {items.map((item, index) => {
          return (
            <ShipmentItem
              key={item.id}
              item={item}
              index={index}
              quotaId={quota.quotaId}
              authorId={quota.user.id}
              dealer={quota.dealer}
              lessee={quota.lessee}
              lessor={lessor}
              isLoading={isLoading}
              isLocked={isLocked}
              telematics={telematics}
              setIsDirty={setIsDirty}
              isChecked={itemCheckedStates[index]} // Передаем состояние каждого чекбокса
              onCheckChange={(checked: boolean) => {
                const updatedCheckedStates = [...itemCheckedStates];
                updatedCheckedStates[index] = checked;
                setItemCheckedStates(updatedCheckedStates);
              }}
              isDisabledCheckbox={
                !items[index].shipmentReportIssueId &&
                (id === quota.user.id || isSuperSalesManager || isSalesManager || isInsideSales)
              }
            />
          );
        })}
      </div>
      <ModalForm open={open} onClose={onClose} width={780}>
        <MultiShipmentReportForm
          quotaId={quota.quotaId}
          checkedItemIds={checkedItemIds}
          onClose={onClose}
        />
      </ModalForm>
    </>
  );
};
