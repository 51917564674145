import { makeStyles, createStyles, Theme, Paper, Portal } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useCounterpartyEditForm } from './useCounterpartyEditForm';
import { useTranslation } from 'react-i18next';
import { GeneralInformationFields } from './GeneralInformationFields';
import { ContactFields } from './ContactFields';
import { AdditionalDataFields } from './AdditionalDataFields';
import { SignersFields } from './SignersFields';
import { RequisitesFields } from './RequisitesFields';
import { Button, Role, useToast } from 'components';
import { useEffect, useRef } from 'react';
import { ApplicationRoles } from 'services';
import { useHistory } from 'react-router-dom';
import { useCounterpartyProperties } from '../CounterpartyRating/useCounterpartyProperties';
import { CounterpartyRisks } from '../CounterpartyRisks';
import { TelematicsDevices } from './TelematicsDevices';
import { useWatch } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    panels: {
      marginBottom: '75px',
    },
    actions: {
      [theme.breakpoints.down(1200)]: {
        width: 'calc(100% - 85px)',
        left: 63,
      },
      display: 'flex',
      justifyContent: 'flex-start',
      width: 'calc(100% - 238px)',
      padding: theme.spacing('20px', '20px'),
      bottom: 0,
      position: 'fixed',
      zIndex: 1,
      left: 216,
    },
    actionButton: {
      marginRight: theme.spacing(2.5),
    },
  })
);

type CounterpartyEditFormProps = {
  setCounterpartyName: (counterpartyName: string) => void;
};

export const CounterpartyEditForm = ({ setCounterpartyName }: CounterpartyEditFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { onSubmit, control, setValue, getValues, clearErrors, groups, ...rest } =
    useCounterpartyEditForm();
  const { isSubmitting, isValid } = rest;
  const counterpartyName = getValues('name');

  useEffect(() => {
    setCounterpartyName(counterpartyName);
  }, [setCounterpartyName, counterpartyName]);

  const {
    location: { state },
  } = useHistory<{ isNew?: boolean } | undefined>();
  const isNew = state?.isNew ?? false;

  const { isFinanced, isEstimated } = useCounterpartyProperties();

  const toast = useToast();

  const isFirstTimeRef = useRef(true);
  useEffect(() => {
    if (isNew && isFirstTimeRef.current && typeof isFinanced === 'boolean') {
      if (!isFinanced) {
        toast('Запрет финансирования по номеру ОКВЭД. Расчет рейтинга не производится.', 'error');
      }
      if (isEstimated) {
        toast('Требуется оценка индустрии. Автоматический расчет рейтинга невозможен.', 'error');
      }
      isFirstTimeRef.current = false;
    }
  }, [isNew, isFinanced, isEstimated, toast, isFirstTimeRef]);

  const telematicsDevices = useWatch({ control, name: 'telematicsDevices' });

  return (
    <form className={classes.root}>
      <div className={classes.panels}>
        <Accordion defaultExpanded={true} disabled>
          <AccordionDetails>
            <GeneralInformationFields
              control={control}
              clearErrors={clearErrors}
              isValid={isValid}
              setValue={setValue}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography variant="subtitle1">{t('POD FT')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CounterpartyRisks />
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography variant="subtitle1">{t('Contacts')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ContactFields control={control} setValue={setValue} />
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography variant="subtitle1">{t('Signers')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SignersFields control={control} />
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography variant="subtitle1">{t('Requisites')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <RequisitesFields control={control} setValue={setValue} />
          </AccordionDetails>
        </Accordion>

        {telematicsDevices && telematicsDevices.length > 0 && (
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography variant="subtitle1">{t('Telematics devices')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TelematicsDevices control={control} />
            </AccordionDetails>
          </Accordion>
        )}

        <Accordion defaultExpanded={true}>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography variant="subtitle1">{t('Additional data')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AdditionalDataFields control={control} />
          </AccordionDetails>
        </Accordion>
      </div>
      <Portal container={document.body}>
        <Paper square className={classes.actions}>
          <Role role={ApplicationRoles.allExceptAssetManager}>
            <div className={classes.actionButton}>
              <Button
                color="primary"
                size="medium"
                type="submit"
                variant="contained"
                disabled={isSubmitting}
                onClick={onSubmit}
              >
                {t('Save')}
              </Button>
            </div>
          </Role>
        </Paper>
      </Portal>
    </form>
  );
};
