import Typography from '@material-ui/core/Typography';
import { Button, Grid, Select, Switch, formatNumber, IconSprite, Role } from 'components';
import { useCalculationFormContext } from 'components/calculator2/CalculationForm/types';
import { useCallback } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ModelAutocomplete } from './ModelAutocomplete';
import { FixedAmountInput } from '../../AmountInput';
import {
  AmountType,
  ApplicationRole,
  NomenclatureMargin,
  QuotaNomenclatureViewModel,
} from 'schema/serverTypes';
import { createStyles, makeStyles, MenuItem, Theme } from '@material-ui/core';
import { RemoveButton } from './RemoveButton';
import { TextField as MuiTextField } from '@material-ui/core';
import { themeOrange as theme } from 'theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      marginRight: theme.spacing(1),
    },
    infoIcon: {
      height: 16,
      position: 'relative',
      marginTop: 3,
      marginRight: theme.spacing(1),
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main,
        '& $infoText': {
          display: 'inline-block',
        },
      },
    },
    infoText: {
      '&&&': {
        color: theme.palette.common.black,
      },
      display: 'none',
      textAlign: 'left',
      position: 'absolute',
      top: 22,
      left: -6,
      zIndex: 5,
      width: 'max-content',
      maxWidth: 200,
      backgroundColor: 'white',
      color: theme.palette.common.black,
      '-webkit-text-fill-color': theme.palette.common.black,
      fontSize: 10,
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: 4,
      padding: theme.spacing(1),
      '&:after': {
        content: "''",
        display: 'block',
        position: 'absolute',
        width: 8,
        height: 8,
        top: -5,
        left: 8,
        backgroundColor: 'white',
        borderLeft: `1px solid ${theme.palette.secondary.main}`,
        borderTop: `1px solid ${theme.palette.secondary.main}`,
        transform: 'rotate(45deg)',
      },
      '&$infoTextService': {
        right: -6,
        left: 'unset',
        '&:after': {
          left: 'calc(100% - 16px)',
        },
      },
    },
    infoTextService: {},
  })
);

export type NomeclaturesListProps = {
  addButtomCssClassName: string;
  onOpenNomenclatures: () => void;
  margins: NomenclatureMargin[];
  quotaId?: number;
  nomenclatures: QuotaNomenclatureViewModel[];
};

type CalculatedMarginProps = {
  index: number;
  margins: NomenclatureMargin[];
};

const CalculatedMargin = (props: CalculatedMarginProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { margins, index } = props;
  const { control } = useCalculationFormContext();
  const code = useWatch({ control, name: `nomenclatures.${index}.code` }) ?? '';
  const id = useWatch({ control, name: `nomenclatures.${index}.id` }) ?? 0;
  const name = useWatch({ control, name: `nomenclatures.${index}.name` }) ?? '';
  const price = useWatch({ control, name: `nomenclatures.${index}.price` }) ?? 0;
  const count = useWatch({ control, name: `nomenclatures.${index}.count` }) ?? 0;
  const year = useWatch({ control, name: `nomenclatures.${index}.year` }) ?? 0;

  const { margin, calcInfoList } = matchMargin(margins, code, id, name, price, count, year) || {};

  const formattedValue = formatNumber(margin, 4) ?? '';

  return (
    <MuiTextField
      name={`margin-${code}`}
      label={t('Calculated margin, %')}
      value={formattedValue}
      variant="outlined"
      size="small"
      fullWidth
      disabled
      InputProps={{
        endAdornment: calcInfoList && calcInfoList.length > 0 && (
          <Role
            role={[
              ApplicationRole.Admin,
              ApplicationRole.RiskManager,
              ApplicationRole.SuperSalesManager,
            ]}
          >
            <div className={classes.infoIcon}>
              <IconSprite icon="menu-catalogs" width={16} color={theme.palette.text.secondary} />
              <div className={classes.infoText}>{calcInfoList?.join(', ')}</div>
            </div>
          </Role>
        ),
      }}
    />
  );
};

export const NomenclaturesList = (props: NomeclaturesListProps) => {
  const { addButtomCssClassName, onOpenNomenclatures, margins = [], nomenclatures } = props;
  const { control } = useCalculationFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'nomenclatures',
  });

  const handleOnAddNomenclature = useCallback(() => {
    append({
      count: 1,
      year: new Date().getFullYear(),
      name: '',
      isSecondHand: false,
    });
  }, [append]);

  const { t } = useTranslation();

  const year = new Date().getFullYear() + 1;

  return (
    <>
      {fields.map((item, index) => {
        return (
          <Grid item xs={24} key={item.id}>
            <Grid container columnSpacing={2} rowSpacing={2.5}>
              <Grid item xl={9} md={9} xs={24}>
                <ModelAutocomplete
                  index={index}
                  item={item}
                  quotaId={props.quotaId}
                  taskId={nomenclatures[index]?.taskId}
                  onOpenNomenclatures={onOpenNomenclatures}
                />
              </Grid>
              <Grid item xl={3} md={3} xs={24}>
                <CalculatedMargin index={index} margins={margins} />
              </Grid>
              <Grid item xl={3} md={4} xs={24}>
                <FixedAmountInput
                  name={`nomenclatures.${index}.price`}
                  label={t('ItemPrice')}
                  amountMode={AmountType.Money}
                  required
                />
              </Grid>
              <Grid item xl={2} md={2} xs={24}>
                <Select
                  name={`nomenclatures.${index}.count`}
                  control={control}
                  label={t('NumberOfItems')}
                  required
                >
                  {Array.from(Array(100)).map((_, i) => {
                    return (
                      <MenuItem key={i + 1} value={i + 1}>
                        {i + 1}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item xl={2} md={3} xs={24}>
                <Select
                  name={`nomenclatures.${index}.year`}
                  control={control}
                  label={t('Year')}
                  required
                >
                  {Array.from(Array(12)).map((_, i) => {
                    return (
                      <MenuItem key={year - i} value={year - i}>
                        {year - i}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item xl={2} md={2} xs={24}>
                <Switch
                  control={control}
                  name={`nomenclatures.${index}.isSecondHand`}
                  labelOn={t('SecondHand')}
                  labelOff={t('New')}
                  type="checkbox"
                />
              </Grid>
              <Grid item xl={2} md={1} xs={24}>
                <RemoveButton index={index} onRemove={remove} append={append} />
              </Grid>
            </Grid>
          </Grid>
        );
      })}
      <Button variant="text" className={addButtomCssClassName} onClick={handleOnAddNomenclature}>
        <Typography variant="subtitle1" color={'primary'}>
          + {t('Add')}
        </Typography>
      </Button>
    </>
  );
};
const matchMargin = (
  margins: NomenclatureMargin[],
  code: string,
  id: number,
  name: string,
  price: number,
  count: number,
  year: number
) => {
  const codeSearch = createCode(code, id, name, price, count, year);
  const margin = margins.find((t) => t.code === codeSearch);
  return margin;
};

const createCode = (
  code: string,
  id: number,
  name: string,
  price: number,
  count: number,
  year: number
) => {
  return `${code !== '' ? code : 'EMPTY_CODE'};${name};${price};${count};${year}`;
};
