import { AmountType } from '../../../../schema';
import { FixedAmountInput } from '../AmountInput';
import { useTranslation } from 'react-i18next';
import React, { Dispatch, SetStateAction } from 'react';
import { useCalculationFormContext } from '../types';
import { useLeasingProductQuery } from '../SaleContractFields/LeaseProductAutocomplete';
import { KofModal } from './KofModal';
import { useModalForm } from '../../../Modal';
import { IconSprite } from '../../../icons';

type CofPercentsProps = {
  isLockedButton?: boolean;
  isCofDisabled?: boolean;
  setIsCofDisabled: Dispatch<SetStateAction<boolean>>;
};

export const CofPercents = (props: CofPercentsProps) => {
  const { t } = useTranslation();
  const { watch } = useCalculationFormContext();

  const salesChannel = watch('leaseProduct');
  const cofPercents = watch('cofPercents');
  const { options = [] } = useLeasingProductQuery();
  const selectedSalesChannelCof =
    options.find((t) => t.name === salesChannel)?.specialCof ?? undefined;
  const modalText =
    selectedSalesChannelCof === cofPercents
      ? `Для данного расчета применен Особый КОФ для канала продаж ${salesChannel}. Все равно разблокировать?`
      : 'Вы уверены?';

  const { isLockedButton, isCofDisabled, setIsCofDisabled } = props;

  const { onOpen, onClose, open } = useModalForm();

  const lockedIcon = (
    <div onClick={isCofDisabled ? onOpen : undefined}>
      <IconSprite icon={isCofDisabled ? 'lock-close' : 'lock-open'} width={12} height={15} />
    </div>
  );

  const onConfirm = () => {
    if (setIsCofDisabled) {
      setIsCofDisabled((prev) => !prev);
    }
    onClose();
  };

  return (
    <>
      <FixedAmountInput
        name="cofPercents"
        label={`${t('Cof')}, %`}
        amountMode={AmountType.Percents}
        required
        disabled={isCofDisabled}
        icon={isLockedButton ? lockedIcon : undefined}
      />
      <KofModal open={open} onClose={onClose} onConfirm={onConfirm} modalText={modalText} />
    </>
  );
};
