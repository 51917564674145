import { createStyles, makeStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useLanguage } from '../utils/useLanguage';
import { WikiItemListViewModel } from '../../schema';

const useStyles = makeStyles((theme) =>
  createStyles({
    table: {
      backgroundColor: theme.palette.common.white,
      fontSize: 12,
      boxShadow: '0px 0px 20px 0px rgb(56 60 97 / 10%)',
      color: theme.palette.common.black,
      display: 'grid',
      gridTemplateColumns: 'auto 250px 150px',
    },
    td: {
      paddingLeft: 6,
      paddingRight: 6,
    },
    rowWrapper: {
      color: theme.palette.secondary.dark,
      display: 'contents',
      '&:hover > div': {
        backgroundColor: theme.palette.background.light,
      },
      '& > div': {
        paddingTop: 16,
        paddingBottom: 16,
        borderBottom: `2px solid ${theme.palette.background.default}`,
      },
    },
    title: {
      paddingLeft: theme.spacing(2.5),
      width: 'auto',
    },
    managerName: {
      whiteSpace: 'nowrap',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1.5),
      maxHeight: '14px',
      fontSize: '12px',
      lineHeight: '1.1',
      color: theme.palette.common.black,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right',
      borderRight: '1px solid ' + theme.palette.secondary.main,
      flexShrink: 0,
    },
    date: {
      whiteSpace: 'nowrap',
      paddingRight: theme.spacing(2.5),
      color: theme.palette.text.primary,
    },
  })
);

type WikiTableProps = {
  rows: WikiItemListViewModel[];
};

export const WikiTable = (props: WikiTableProps) => {
  const classes = useStyles();
  const { rows } = props;

  return (
    <>
      <div className={classes.table}>
        {rows.map((row: WikiItemListViewModel) => {
          return <TableItem key={row.id} row={row} />;
        })}
      </div>
    </>
  );
};

const TableItem = ({ row }: { row: WikiItemListViewModel }) => {
  const classes = useStyles();
  const lang = useLanguage();
  const { id, title, author, updatedDate } = row;

  return (
    <Link to={`/wiki/${id}`} className={classes.rowWrapper}>
      <div className={clsx(classes.td, classes.title)}>{title}</div>
      <div className={classes.td}>
        <Typography component="span" variant={'body2'} className={classes.managerName}>
          {author.name}
        </Typography>
      </div>
      <div className={clsx(classes.td, classes.date)}>
        {dayjs(updatedDate).locale(lang).format('DD MMM YYYY HH:mm')}
      </div>
    </Link>
  );
};
