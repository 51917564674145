import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Button } from 'components';
import { ModalForm } from 'components/Modal';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalRoot: {
      textAlign: 'center',
    },
    modalTitle: {
      textAlign: 'center',
      marginBottom: theme.spacing(3),
    },
    modalText: {
      color: theme.palette.text.primary,
    },
    modalButton: {
      marginTop: theme.spacing(2),
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
    },
  })
);

export type KofModalProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  modalText: string;
};

export const KofModal = (props: KofModalProps) => {
  const classes = useStyles();
  const { open, onClose, onConfirm, modalText } = props;
  const { t } = useTranslation();

  return (
    <ModalForm open={open} onClose={onClose} width={500}>
      <div className={classes.modalRoot}>
        <Typography variant={'h2'} className={classes.modalTitle}>
          Разблокировка КОФ
        </Typography>
        <Typography variant="subtitle2" className={classes.modalText}>
          {modalText}
        </Typography>
        <div>
          <Button variant="contained" className={classes.modalButton} onClick={onConfirm}>
            {t('Yes')}
          </Button>
          <Button variant="outlined" onClick={onClose} className={classes.modalButton}>
            {t('No')}
          </Button>
        </div>
      </div>
    </ModalForm>
  );
};
