import { Checkbox as MuiCheckbox, createStyles, makeStyles, Paper, Theme } from '@material-ui/core';
import { DatePicker, Button, IconCheckbox, IconSprite, useToast } from 'components';
import { useTranslation } from 'react-i18next';
import { QuotaCalculationResult, ShipmentItemViewModel, Telematics } from 'schema';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSetShipmentDateMutation } from 'services/api/useShipmentItemsBackend';
import { ShipmentItem } from './ShipmentItem';
import { useQueryClient } from 'react-query';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panels: {
      marginBottom: (props: any) => (props.isTask ? '0' : '75px'),
    },
    legendList: {
      display: 'flex',
    },
    legendItem: {
      display: 'flex',
      alignItems: 'center',
      '& + &': {
        marginLeft: theme.spacing(2.5),
      },
      '&:before': {
        content: '""',
        display: 'inline-block',
        borderRadius: '50%',
        width: 15,
        height: 15,
        marginRight: theme.spacing(1),
      },
      '&.success:before': {
        border: `3px solid ${theme.palette.green.main}`,
      },
      '&.error:before': {
        border: `3px solid ${theme.palette.chartPurple.main}`,
      },
    },
    actions: {
      padding: theme.spacing(2, 2.5),
      marginBottom: theme.spacing(2),
      borderRadius: 0,
      '& > *': {
        marginRight: theme.spacing(2.5),
      },
    },
    multiActionForm: {
      display: 'flex',
      gap: theme.spacing(2.5),
      alignItems: 'center',
    },
    mainDatePicker: {
      width: 280,
    },
  })
);

type MultiShipmentDateFormProps = {
  quota: QuotaCalculationResult;
  lessor?: string;
  items: ShipmentItemViewModel[];
  isLocked: boolean;
  isLoading: boolean;
  telematics?: Telematics;
  setIsDirty?: (value: boolean) => void;
  isTask?: boolean;
  isDisabledCheckbox?: boolean;
  setCurrentView: Dispatch<SetStateAction<string | null>>;
};

export const MultiShipmentDateForm = (props: MultiShipmentDateFormProps) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const toast = useToast();
  const { quota, lessor, items, isLocked, isLoading, telematics, setIsDirty, setCurrentView } =
    props;

  const [allChecked, setAllChecked] = useState(false);
  const [itemCheckedStates, setItemCheckedStates] = useState<boolean[]>([]);

  useEffect(() => {
    if (items && items.length > 0) {
      setItemCheckedStates(items.map(() => false));
    }
  }, [items]);

  const handleCheckAll = () => {
    const newCheckedState = !allChecked;

    const updatedCheckedStates = itemCheckedStates.map((checked, index) => {
      return newCheckedState;
    });

    // Set the states
    setAllChecked(newCheckedState);
    setItemCheckedStates(updatedCheckedStates);
  };

  useEffect(() => {
    // Determine if all *enabled* items are checked
    const allItemsChecked = items.every((item, index) => {
      return itemCheckedStates[index];
    });
    setAllChecked(allItemsChecked);
  }, [itemCheckedStates, items]);

  const checkedItemIds = useMemo(() => {
    return items
      .filter((_, index) => itemCheckedStates[index]) // Фильтруем элементы, которые отмечены
      .map((item) => item.id); // Извлекаем id из отмеченных элементов
  }, [itemCheckedStates, items]);

  const { handleSubmit, control } = useForm({
    mode: 'onBlur',
  });
  const queryClient = useQueryClient();

  const { mutateAsync } = useSetShipmentDateMutation(quota.quotaId, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey.indexOf('shipment') > -1;
        },
      });
      setCurrentView(null);
      toast(t('SuccessMessage'), 'success');
    },
    onError: () => {
      toast(t('ErrorMessage'), 'error');
    },
  });

  const onSubmit = useMemo(() => {
    const submit = async (form: any) => {
      const obj = {
        quotaId: quota.quotaId,
        ids: checkedItemIds,
        ...form,
      };
      await mutateAsync(obj);
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync, quota.quotaId, checkedItemIds]);

  return (
    <>
      <Paper className={classes.actions}>
        <form onSubmit={onSubmit} className={classes.multiActionForm}>
          <MuiCheckbox
            checked={allChecked ?? false}
            onChange={handleCheckAll}
            checkedIcon={<IconCheckbox checked={true} />}
            icon={<IconCheckbox checked={false} />}
            color={'primary'}
            size="small"
          />
          <IconSprite icon="date" width={20} height={22} />
          <DatePicker
            key="main"
            label={t('Shipping date')}
            name="date"
            control={control}
            className={classes.mainDatePicker}
            disableFuture={true}
          />
          <Button variant="contained" type="submit" disabled={checkedItemIds.length === 0}>
            {t('Save')}
          </Button>
          <Button variant="outlined" onClick={() => setCurrentView(null)}>
            {t('Cancel')}
          </Button>
        </form>
      </Paper>
      <div className={classes.panels}>
        {items.map((item, index) => {
          return (
            <ShipmentItem
              key={item.id}
              item={item}
              index={index}
              quotaId={quota.quotaId}
              authorId={quota.user.id}
              dealer={quota.dealer}
              lessee={quota.lessee}
              lessor={lessor}
              isLoading={isLoading}
              isLocked={isLocked}
              telematics={telematics}
              setIsDirty={setIsDirty}
              isChecked={itemCheckedStates[index]} // Передаем состояние каждого чекбокса
              onCheckChange={(checked: boolean) => {
                const updatedCheckedStates = [...itemCheckedStates];
                updatedCheckedStates[index] = checked;
                setItemCheckedStates(updatedCheckedStates);
              }}
            />
          );
        })}
      </div>
    </>
  );
};
