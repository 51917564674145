import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSendTo1CMutation } from '../../services';
import { useToast } from '../Toast';

export const useSendTo1CHandler = (quotaId: number, onClose1C?: (ev?: any) => void) => {
  const { t } = useTranslation();
  const toast = useToast();

  const { mutateAsync, isLoading: isSendingTo1C } = useSendTo1CMutation(quotaId ?? 0);
  const errorMessage = t('ErrorMessage');
  const successMessage = t('Contract successfully has been sent to 1C');

  const handleSendTo1C = useCallback(async () => {
    const result = await mutateAsync(null);
    if (result.success) {
      toast(successMessage, 'success');
      if (onClose1C) {
        onClose1C();
      }
    } else {
      toast(errorMessage, 'error');
    }
  }, [mutateAsync, toast, successMessage, errorMessage, onClose1C]);

  return { handleSendTo1C, isSendingTo1C };
};
