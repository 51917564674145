import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { themeOrange as theme } from 'theme';
import clsx from 'clsx';
import { IconSprite } from '../../../icons';
import { SeasonalPaymentScheduleProps } from './SeasonalPaymentSchedule';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    toggle: {
      zIndex: 1000,
      position: 'absolute',
      right: 20,
      top: 8,
      display: 'flex',
    },
    btn: {
      width: 24,
      height: 24,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      '& + &': {
        marginLeft: theme.spacing(1),
      },
      '&$active': {
        border: '1px solid' + theme.palette.text.secondary,
        borderRadius: 2,
      },
    },
    active: {},
  })
);

export const ScheduleToggle = (props: SeasonalPaymentScheduleProps) => {
  const classes = useStyles();
  const [old, setOld] = useState<boolean>(true);

  const { OldComponent, NewComponent, ...calculationResult } = props;

  return (
    <div className={classes.root}>
      <div className={classes.toggle}>
        <div
          onClick={() => setOld(false)}
          className={clsx(classes.btn, !old ? classes.active : null)}
        >
          <IconSprite icon={'switcher-list'} width={12} color={theme.palette.secondary.dark} />
        </div>
        <div
          onClick={() => setOld(true)}
          className={clsx(classes.btn, old ? classes.active : null)}
        >
          <IconSprite icon={'menu-groups'} width={16} color={theme.palette.secondary.dark} />
        </div>
      </div>
      {old ? <OldComponent {...calculationResult} /> : <NewComponent {...calculationResult} />}
    </div>
  );
};
